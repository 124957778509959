/*
 * DTOs for requests and responses from APIs
 */

import { Conversation, Appointment } from "./PatientServerDTOs";

/** Conversations */
export enum TrustedDeviceState {
    Trusted = "Trusted",
    NotTrusted = "NotTrusted",
};

export type AuthenticatePatientRequest = {
    day: number;
    month: number;
    year: number;
};

export type ConversationAuthenticationResult = {
    success: boolean;
    canRetry: boolean;
    conversationId: string;
    trustedDeviceState: TrustedDeviceState;
};

export type FetchConversationResponse =
    | {
          success: true;
          conversation: Conversation;
          unauthorised: false;
      }
    | {
          success: false;
          conversation: null;
          unauthorised: boolean;
      };

export type ConversationContext = {
    workspaceName: string;
    isCancelled: boolean;
    isLocked: boolean;
    isNhsLoginEnabled: boolean;
    isDownForMaintenance: boolean;
    isViewAllConversationsEnabled: boolean;
    productOrigin: ProductOrigin;
};

export type FetchConversationContextResponse =
    | {
          success: true;
          workspaceName: string;
          isCancelled: boolean;
          isLocked: boolean;
          isNhsLoginEnabled: boolean;
          isDownForMaintenance: boolean;
          productOrigin: ProductOrigin;
      }
    | {
          success: false;
          workspaceName: null;
          isCancelled: null;
          isLocked: null;
          isNhsLoginEnabled: false;
          isDownForMaintenance: false;
          productOrigin: null;
      };

//Keep in sync with server side ConversationProductOrigin
export enum ProductOrigin {
    None = "None",
    Questionnaire = "Questionnaire",
    PatientResponse = "PatientResponse",
    ClinicianAttachment = "ClinicianAttachment",
    PatientResponseAndAttachment = "PatientResponseAndAttachment",
    BookingLink = "BookingLink",
}

export type AuthenticationResponse = {
    authenticationState: AuthenticationState;
    isDownForMaintenance: boolean;
};

//Keep in sync with server side AppointmentRequestType
export enum AuthenticationState {
    None = "None",
    NhsLogin = "NhsLogin",
    DateOfBirth = "DateOfBirth",
}

export type SendPatientResponseRequest = {
    message: string;
    attachmentIds: string[];
    responseId: string;
};

export type FloreyAttachmentRequest = {
    conversationId: string;
    documentUrlId: string;
    shouldSaveFile: boolean;
};

/** Appointments */

export type FetchAppointmentDetailsResponse =
    | { success: true; appointment: Appointment }
    | { success: false; appointment: null };

export type BaseDocument = {
    fileName: string;
    urlId: string;
    createdAt: string;
    sentBy: string;
};

export enum OrganisationResourceType {
    None,
    Map,
    Other,
    AdviceAndGuidance,
    Instructions,
}

export type OrganisationDocument = BaseDocument & {
    type: OrganisationResourceType;
};

export type FetchDocumentsForPatientResponse = {
    success: boolean;
    documents: BaseDocument[];
};

export type FetchOrganisationDocumentsResponse = {
    success: boolean;
    documents: OrganisationDocument[];
};

export type PatientAppointmentRequest = {
    message: string;
    attachmentIds: string[];
    requestType: AppointmentRequestType;
    requestTrackingId: string;
};

//Keep in sync with server side AppointmentRequestType
export enum AppointmentRequestType {
    None = 0,
    Reschedule = 1,
    Cancel = 2,
    GeneralEnquiry = 3,
}

export type FloreyRedirectResponse =
    | {
          getTokenSuccess: true;
          floreyRedirectUrl: string;
          statusCode: number | null;
      }
    | {
          getTokenSuccess: false;
          floreyRedirectUrl: null;
          statusCode: number | null;
      };

export type AppointmentBookingResponse =
    | {
          getTokenSuccess: true;
          bookingAuthTokenUrl: string;
          statusCode: number | null;
      }
    | {
          getTokenSuccess: false;
          bookingAuthTokenUrl: null;
          statusCode: number | null;
      };

export type ConversationListItemLastMessage = {
    senderInitials: string | null;
    body: string;
    sendTime: string;
};

export type ConversationListItem = {
    organisationName: string;
    practitionerName: string | null;
    lastMessage: ConversationListItemLastMessage;
    conversationUrlId: string;
};

export type FetchConversations = {
    success: boolean;
    conversations: ConversationListItem[];
    unauthorised: boolean;
};

export type AttachmentResponse =
    | {
          success: true;
          url: string;
      }
    | {
          success: false;
          url: null;
      };

export enum ContactMethod {
    Sms = "Sms",
    Email = "Email",
}

export type TwoFactorCodeResponse = {
    contactMethod: ContactMethod;
    obfuscatedContactDetails: string;
    nextCodeAvailableAt: string;
};
